import React, { Component } from "react";
import Layout from "../components/layout";

//NPM
import styled from "styled-components";
import moment from "moment";
import Fade from "react-reveal/Fade";

//Components
import EventTitleBlock from "../components/event-title-block";
import Button from "../components/button";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { colors } from "../colors/colors";
import { graphql } from "gatsby";
import SectionTitle from "../components/section-title";
import { EventsContainer } from "../pages";
import EventCard from "../components/event-card";
import { Space } from "@mantine/core";
import Jumbotron from "../components/jumbotron";

const FlyerContainer = styled.div`
  height: 75vh;
  width: 100%;
  position: relative;
  border-bottom: 2px solid ${colors.red};
  margin-bottom: 10px;
`;

const EventCopy = styled.pre`
  white-space: pre-wrap;
  word-break: break-word;
  line-height: normal;
  display: block;
  font-family: "Poppins";
  color: ${colors.copy};
  margin: 0;
  padding: 0;
  max-width: 650px;
  background: none;
  display: inline-block;
  line-height: 90%;

  p {
    font-size: 0.9rem;
    line-height: 130%;
    margin-bottom: 8px;
  }
`;

const CopyContainer = styled.div`
  width: 100%;
  padding: 20px 5%;
  min-height: 300px;
  max-width: 1080px;
  margin: 0 auto;
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

class EventPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: "",
      events: [],
      eventCopy: "",
      eventDay: "",
      eventMonth: "",
    };
  }

  componentDidMount() {
    //Function to order by date
    const data = this.props.data.events.edges;
    const monthsAway = moment().add("months", 3).toDate();
    const sortedData = data.filter(function (event) {
      //Return events withinn the next 3 months
      return (
        new Date(event.node.acf.date) < monthsAway &&
        new Date(event.node.acf.date) > moment().toDate()
      );
    });
    const orderedEvents = sortedData.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.node.acf.date) - new Date(a.node.acf.date);
    });

    this.setState({ events: orderedEvents.reverse() });
  }

  render() {
    const data = this.props.data.wordpressPost;
    const { events } = this.state;

    return (
      <Layout>
        <SEO
          title={data.acf.name}
          description={data.acf.tagline}
          keywords={[
            `yup`,
            `life`,
            `FOC`,
            `Festival`,
            `Colour`,
            `Backyard`,
            `Melee`,
            `Events`,
            `Fete`,
            `Trinidad`,
            `Carnival`,
            `Party`,
            `Fete`,
          ]}
        />
        <Jumbotron
          image={
            data?.acf?.leaderboard_flyer?.source_url ??
            data.acf.flyer.source_url
          }
        />
        {/* <FlyerContainer>
          <Img
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              objectFit: "contain",
            }}
            fadeIn
            objectFit="contain"
            fluid={data.acf.flyer.localFile.childImageSharp.fluid}
          />
        </FlyerContainer> */}
        <EventTitleBlock
          title={data.acf.name}
          subtitle={data.acf.tagline}
          day={moment(data.acf.date).format("Do")}
          month={moment(data.acf.date).format("MMM")}
        />
        {data.acf.ticket_url && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={data.acf.ticket_url}
          >
            <Button title="Get Tickets Now" />
          </a>
        )}
        <CopyContainer>
          <Fade>
            {data.content ? (
              <EventCopy
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              />
            ) : (
              <EventCopy>Event details coming soon...</EventCopy>
            )}
          </Fade>
        </CopyContainer>
        <ActionsContainer>
          {data.acf.ticket_url && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={data.acf.ticket_url}
            >
              <Button margin title="Get Tickets Now" />
            </a>
          )}
          {/* {data.acf.wristband_url && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={data.acf.wristband_url}
            >
              <Button margin title="Activate Your Wristband" />
            </a>
          )} */}
        </ActionsContainer>
        <SectionTitle title="More Events" subtitle="" />
        <EventsContainer>
          {events.map((event) => (
            <EventCard
              key={event.node.id}
              flyerUrl={event.node.acf.flyer.source_url}
              title={event.node.acf.name}
              date={event.node.acf.date}
              slug={event.node.slug}
              fluid={event.node.acf.flyer.localFile.childImageSharp.fluid}
            />
          ))}
        </EventsContainer>
      </Layout>
    );
  }
}

export default EventPage;

export const postsQuery = graphql`
  query ($id: String!, $events: String = "Event") {
    wordpressPost(id: { eq: $id }) {
      title
      content
      acf {
        tagline
        name
        date
        flyer {
          source_url
          localFile {
            childImageSharp {
              fluid(maxHeight: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        leaderboard_flyer {
          source_url
          localFile {
            childImageSharp {
              fluid(maxHeight: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        ticket_url
      }
    }
    events: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $events } } } }
    ) {
      edges {
        node {
          title
          id
          content
          slug
          acf {
            name
            flyer {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxHeight: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            leaderboard_flyer {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxHeight: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            date
            ticket_url
            hide
            tagline
          }
        }
      }
    }
  }
`;
