import React from "react";
import styled from "styled-components";
import { colors } from "../colors/colors";

const BlockContainer = styled.div`
  display: grid;
  padding: 15px;
  grid-template-columns: 20% 80%;
  justify-items: right left;
  align-items: start;
  color: ${colors.copy};
  max-width: 1080px;
  margin: 0 auto;

  ::after {
    content: "";
    width: 150px;
    display: block;
    margin: 30px auto;
    height: 3px;
    background-color: ${colors.red};
  }
`;

const Title = styled.h1`
  font-family: "Tanker";
  font-size: 45px;
  font-weight: 400;
  margin: 0;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 35px;
  }
`;

const Date = styled.h2`
  font-family: "Tanker";
  font-size: 40px;
  margin: 0;
  text-align: center;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 28px;
  }
`;

const SubTitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin: 0;
`;

const DateBlock = styled.div`
  display: grid;
  grid-template-rows: repeat(2 1fr);
`;

const TitleBlock = styled.div`
  margin: 0 0 0 10px;
`;

const EventTitleBlock = (props) => (
  <BlockContainer>
    <DateBlock>
      <Date>{props.month}</Date>
      <Date style={{ color: "#4C4C4C" }}>{props.day}</Date>
    </DateBlock>
    <TitleBlock>
      <Title>{props.title}</Title>
      <SubTitle>{props.subtitle}</SubTitle>
    </TitleBlock>
  </BlockContainer>
);

export default EventTitleBlock;
